<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled,
    }"
    @click="openTagModal(item)"
  >
    <!-- @click="
      () => {
        if (item.title == 'Add tag') {
          isTaskHandlerSidebarActive = true;
        }
      }
    " -->
    <b-link v-bind="linkProps" class="d-flex align-items-center">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>

    <b-modal
      :visible="isTaskHandlerSidebarActive"
      cancel-variant="outline-secondary"
      ok-title="Save "
      cancel-title="Close"
      centered
      title="Tag Form"
    >
      <b-form>
        <b-form-group>
          <label for="Name">Name:</label>
          <b-form-input id="Name" type="text" placeholder="Name" />
        </b-form-group>
        <b-form-group>
          <label for="Color">Color</label>
          <color-picker
            v-bind="color"
            @input="onInput"
            style="margin-left: 19%"
          ></color-picker>
        </b-form-group>
      </b-form>
    </b-modal>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from "@core/libs/acl";
import { BLink, BBadge } from "bootstrap-vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
import ColorPicker from "@radial-color-picker/vue-color-picker";
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BCardText,
  BModal,
  VBModal,
  BForm,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    BPagination,
    BFormGroup,
    BFormSelect,
    BCardText,
    BModal,
    VBModal,
    BForm,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,

    ColorPicker,
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openTagModal(item) {
      if (item.title == "Add tag") {
        this.isTaskHandlerSidebarActive = true;
      } else {
        this.isTaskHandlerSidebarActive = false;
      }
    },
    getCellStyle() {
      return {
        "--tagsColor":
          "hsla(" +
          this.color.hue +
          "," +
          this.color.saturation +
          "%" +
          "," +
          this.color.luminosity +
          "%" +
          "," +
          this.color.alpha +
          ")",
      };
    },
    onInput(hue) {
      this.color.hue = hue;
    },
    colorUser(colorRow) {
      var color = {
        type: "rgb",
        channels: [colorRow],
      };
      return color;
    },
    colorChanged(color) {
      this.color = color;
    },
    changeAddTagState() {
      this.isAddNewUserSidebarActive = !this.isAddNewUserSidebarActive;
    },
  },
  data() {
    return {
      // color: {
      //   type: "rgb",
      //   channels: [205, 103, 205, 100],
      // },
      isTaskHandlerSidebarActive: false,
      color: {
        hue: 50,
        saturation: 100,
        luminosity: 50,
        alpha: 1,
      },
    };
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    );
    const { t } = useI18nUtils();
    const { canViewVerticalNavMenuLink } = useAclUtils();

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    };
  },
};
</script>
