export default [
  {
    header: 'User option',
  },

  {
    title: 'Group',
    route: 'Group',
    icon: 'UsersIcon',
  },
  {
    title: 'Friends',
    route: 'friends',
    icon: 'UserIcon',
  },
  {
    title: 'Tags',
    route: 'tags',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'tags list',
        route: 'apps-tags',
      },
      // {
      //   title: 'Add tag',
      //   route: '',
      // },
      // {
      //   title: 'Tag 1',
      //   route: ''
      // },
      // {
      //   title: 'School',
      //   route: '',
      // },
      // {
      //   title: 'Work',
      //   route: '',
      // },
    ],
  },
  // {
  //   title: 'Profile',
  //   route: 'pages-profile',
  //   icon: 'UserIcon',
  // },
  {
    title: 'Profile',
    route: 'Profile',
    icon: 'SettingsIcon',
  }



]
