export default [
  {
    title: 'Meeting',
    route: 'apps-meetings',
    icon: 'CoffeeIcon',
    children: [
      {
        title: 'My Requests',
        route: 'apps-requests',
      },
      {
        title: 'My Meetings',
        route: 'apps-meetings',
      },
      {
        title: 'Add Meeting',
        route: 'apps-meetings-add'
      },
    ],
  },
  {
    title: 'Goals',
    route: 'apps-objectifs',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'Projects',
    route: 'projects',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Project list',
        route: 'apps-projects',
      },
      {
        title: 'Add project',
        route: 'add-project'
      }
    ],
  },
  {
    title: 'Chat',
    route: 'Chat',
    // tag: '2',
    icon: 'MessageSquareIcon',
  },


]
