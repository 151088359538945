/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Account Settings', route: { name: 'pages-account-setting' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'Calendar', route: { name: 'apps-calendar' }, icon: 'CalendarIcon', isBookmarked: true },
      { title: 'Chat', route: { name: 'apps-chat' }, icon: 'MessageSquareIcon', isBookmarked: true },
      { title: 'Dashboard Analytics', route: { name: 'dashboard-analytics' }, icon: 'ActivityIcon', isBookmarked: false },
    
      { title: 'FAQ', route: { name: 'pages-faq' }, icon: 'HelpCircleIcon', isBookmarked: false },
      { title: 'Feather', route: { name: 'ui-feather' }, icon: 'FeatherIcon', isBookmarked: false },
      { title: 'File Input', route: { name: 'forms-element-file-input' }, icon: 'FileIcon', isBookmarked: false },
      { title: 'Forgot Password V1', route: { name: 'auth-forgot-password-v1' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Forgot Password V2', route: { name: 'auth-forgot-password-v2' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Form Datepicker', route: { name: 'forms-element-datepicker' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Form Layout', route: { name: 'form-layout' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'Form Rating', route: { name: 'forms-element-rating' }, icon: 'StarIcon', isBookmarked: false },
      { title: 'Form Repeater', route: { name: 'form-repeater' }, icon: 'StarIcon', isBookmarked: false },
      { title: 'Form Tag', route: { name: 'forms-element-tag' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Form Timepicker', route: { name: 'forms-element-timepicker' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Form Validation', route: { name: 'form-validation' }, icon: 'CheckCircleIcon', isBookmarked: false },
      { title: 'Form Wizard', route: { name: 'form-wizard' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Good Table', route: { name: 'table-good-table' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'I18n', route: { name: 'extensions-i18n' }, icon: 'GlobeIcon', isBookmarked: false },
      { title: 'Image', route: { name: 'components-image' }, icon: 'ImageIcon', isBookmarked: false },
      { title: 'Input Group', route: { name: 'forms-element-input-group' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Input Mask', route: { name: 'forms-element-input-mask' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Input', route: { name: 'forms-element-input' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Invoice Add', route: { name: 'apps-invoice-add' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Invoice Edit', route: { name: 'apps-invoice-edit', params: { id: 4987 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Invoice List', route: { name: 'apps-invoice-list' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Invoice Preview', route: { name: 'apps-invoice-preview', params: { id: 4987 } }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Knowledge Base Category', route: { name: 'pages-knowledge-base-category' }, icon: 'InfoIcon', isBookmarked: false, },
      { title: 'Knowledge Base Question', route: { name: 'pages-knowledge-base-question' }, icon: 'InfoIcon', isBookmarked: false, },
      { title: 'Knowledge Base', route: { name: 'pages-knowledge-base' }, icon: 'InfoIcon', isBookmarked: false },
      { title: 'Leaflet', route: { name: 'maps-leaflet' }, icon: 'MapPinIcon', isBookmarked: false },
      { title: 'List Group', route: { name: 'components-list-group' }, icon: 'ListIcon', isBookmarked: false },
      { title: 'Login V1', route: { name: 'auth-login-v1' }, icon: 'LogInIcon', isBookmarked: false },
      { title: 'Login V2', route: { name: 'auth-login-v2' }, icon: 'LogInIcon', isBookmarked: false },
      { title: 'Media Objects', route: { name: 'components-media' }, icon: 'ImageIcon', isBookmarked: false },
      { title: 'Modal', route: { name: 'components-modal' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Nav', route: { name: 'components-nav' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Not Authorized', route: { name: 'misc-not-authorized' }, icon: 'XOctagonIcon', isBookmarked: false },
      { title: 'Overlay', route: { name: 'components-overlay' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Pagination Nav', route: { name: 'components-pagination-nav' }, icon: 'HashIcon', isBookmarked: false },
      { title: 'Pagination', route: { name: 'components-pagination' }, icon: 'HashIcon', isBookmarked: false },
      { title: 'Pill Badge', route: { name: 'components-pill-badge' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Pill', route: { name: 'components-pill' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Popover', route: { name: 'components-popover' }, icon: 'TagIcon', isBookmarked: false },
      { title: 'Pricing', route: { name: 'pages-pricing' }, icon: 'DollarSignIcon', isBookmarked: false },
      { title: 'Product Details', route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } }, icon: 'BoxIcon', isBookmarked: false, },
      { title: 'Profile', route: { name: 'pages-profile' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Progress', route: { name: 'components-progress' }, icon: 'ChevronsRightIcon', isBookmarked: false },
      { title: 'Quill Editor', route: { name: 'extensions-quill-editor' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Radio', route: { name: 'forms-element-radio' }, icon: 'DiscIcon', isBookmarked: false },
      { title: 'Register V1', route: { name: 'auth-register-v1' }, icon: 'UserPlusIcon', isBookmarked: false },
      { title: 'Register V2', route: { name: 'auth-register-v2' }, icon: 'UserPlusIcon', isBookmarked: false },
      { title: 'Reset Password V1', route: { name: 'auth-reset-password-v1' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Reset Password V2', route: { name: 'auth-reset-password-v2' }, icon: 'KeyIcon', isBookmarked: false },
      { title: 'Select', route: { name: 'forms-element-select' }, icon: 'AlignCenterIcon', isBookmarked: false },
      { title: 'Shop', route: { name: 'apps-e-commerce-shop' }, icon: 'ArchiveIcon', isBookmarked: false },
      { title: 'Sidebar', route: { name: 'components-sidebar' }, icon: 'SidebarIcon', isBookmarked: false },
      { title: 'Slider', route: { name: 'extensions-slider' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Spinbutton', route: { name: 'forms-element-spinbutton' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Spinner', route: { name: 'components-spinner' }, icon: 'LoaderIcon', isBookmarked: false },
      { title: 'Statistics Cards', route: { name: 'card-statistic' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Sweet Alert', route: { name: 'extensions-sweet-alert' }, icon: 'BellIcon', isBookmarked: false },
      { title: 'Swiper', route: { name: 'extensions-swiper' }, icon: 'ImageIcon', isBookmarked: false },
      { title: 'Switch', route: { name: 'forms-element-switch' }, icon: 'ToggleRightIcon', isBookmarked: false },
      { title: 'Tab', route: { name: 'components-tab' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Textarea', route: { name: 'forms-element-textarea' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Time', route: { name: 'components-time' }, icon: 'ClockIcon', isBookmarked: false },
      { title: 'Timeline', route: { name: 'components-timeline' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Toastification', route: { name: 'extensions-toastification' }, icon: 'BellIcon', isBookmarked: false },
      { title: 'Toasts', route: { name: 'components-toasts' }, icon: 'BellIcon', isBookmarked: false },
      { title: 'Todo', route: { name: 'apps-todo' }, icon: 'CheckSquareIcon', isBookmarked: true },
      { title: 'Tooltip', route: { name: 'components-tooltip' }, icon: 'CopyIcon', isBookmarked: false },
      { title: 'Tour', route: { name: 'extensions-tour' }, icon: 'GlobeIcon', isBookmarked: false },
      // { title: 'Tree', route: { name: 'extensions-tree' }, icon: 'GitCommitIcon', isBookmarked: false },
      { title: 'Typography', route: { name: 'ui-typography' }, icon: 'TypeIcon', isBookmarked: false },
      { title: 'Under Maintenance', route: { name: 'misc-under-maintenance' }, icon: 'MonitorIcon', isBookmarked: false },
      { title: 'Users Edit', route: { name: 'apps-users-edit', params: { id: 21 } }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Users List', route: { name: 'apps-users-list' }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Users View', route: { name: 'apps-users-view', params: { id: 21 } }, icon: 'UserIcon', isBookmarked: false },
      { title: 'Vue Select', route: { name: 'extensions-vue-select' }, icon: 'AlignCenterIcon', isBookmarked: false },
      { title: 'Wishlist', route: { name: 'apps-e-commerce-wishlist' }, icon: 'HeartIcon', isBookmarked: false },
    ],
  },
  contacts: {
    key: 'name',
    data: [ 
    ],
  },
}
/* eslint-enable */
